import React from "react"

const HeroSection = () => (
  <section className="min-h-[60vh] flex items-center">
    <div className="container mx-auto max-w-6xl px-6 py-10">
      <h1 className="font-medium text-5xl leading-normal tracking-tighter">
        Insights, thoughts &
        <br />
        announcements from the studio.
      </h1>
      <p className="mt-5 leading-loose">
        We share tips, tutorials, and lessons from recent experiments on the
        web.
        <br />
        Make sure you visit our blog daily.
      </p>
    </div>
  </section>
)

export default HeroSection
