import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const NewsListSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulSingleNews(sort: { order: DESC, fields: date }) {
        nodes {
          id
          title
          slug
          date(formatString: "DD MMM, YYYY")
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const news = data?.allContentfulSingleNews?.nodes ?? []

  return (
    <section className="border-t">
      <div className="container mx-auto max-w-6xl px-6 py-28">
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {news.map(({ id, title, image, date, slug }) => (
            <div key={id} className="group">
              <Link to={`/news/${slug}`}>
                <div className="overflow-hidden">
                  <GatsbyImage
                    image={getImage(image)}
                    className="transition duration-500 ease-in-out transform group-hover:scale-125"
                    alt={title}
                  />
                </div>
                <div className="mt-4 w-9/12 mx-auto text-center space-y-2">
                  <h5 className="text-sm text-gray-500 group-hover:text-gray-400 transition duration-500 ease-in-out">
                    {date}
                  </h5>
                  <h3 className="group-hover:text-gray-400 transition duration-500 ease-in-out">
                    {title}
                  </h3>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default NewsListSection
